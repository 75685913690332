import { Button, Form, Input } from "antd";
import React, { useState } from "react";
import { images } from "../../Assets";
import { NavLink, useNavigate } from "react-router-dom";
import { forgotPassword } from "../../ApiServices/Apifun";
import { dispatchtoast } from "../../Utils";

const Forgotpassword = () => {
  const navigate = useNavigate();
  const [btnLoading, setBtnLoading] = useState(false);
  const handleFinish = (val) => {
    setBtnLoading(true);
    forgotPassword(val)
      .then((res) => {
        if (res.status) {
          dispatchtoast(res.message)
          navigate("/otp", {
            state: {
              from: "forgot",
              mobile: val.mobile,
              verificationToken: res.verificationToken,
            },
          });
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setBtnLoading(false));
  };
  return (
    <>
      <div className="flex bg-black justify-center items-center h-screen">
        <div className=" p-8 m-4 w-[400px] rounded-xl bg-primary">
          <img alt="Logo" src={images.Logo} className="w-44 h-28 m-auto" />
          <p className="text-white  text-center font-semibold text-xl">
            Forgot Password
          </p>
          <div className="form w-full">
            <Form
              onFinish={(val) => handleFinish(val)}
              onFinishFailed={(err) => console.log(err)}
            >
              <div>
                <p className="text-white font-semibold">Mobile</p>
                <Form.Item
                  name="mobile"
                  rules={[
                    {
                      required: true,
                      message: "Enter mobile number!",
                    },
                  ]}
                  className="w-full"
                  style={{ color: "white" }}
                  labelCol={{ span: 24 }}
                >
                  <Input
                    name="mobile"
                    placeholder="Enter mobile number"
                    className="rounded-full mt-1 px-3 border-none hover:border-primary"
                  />
                </Form.Item>
              </div>
              <div>
                <Form.Item>
                  <Button
                    loading={btnLoading}
                    htmlType="submit"
                    className="text-primary  hover:border hover:border-white mt-8 w-full rounded-full border-none  transition-all font-semibold bg-white"
                  >
                    Send OTP
                  </Button>
                </Form.Item>
              </div>
              <NavLink to="/">
                <p className="text-white text-center font-semibold cursor-pointer">
                  Go to Login
                </p>
              </NavLink>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Forgotpassword;
