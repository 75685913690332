import Forgotpassword from "../Views/Auth/ForgotPassword";
import Login from "../Views/Auth/Login";
import Otp from "../Views/Auth/Otp";
import Register from "../Views/Auth/Register";
import ResetPassword from "../Views/Auth/ResetPassword";
import PublicAuth from "./PublicAuth";

export const PublicRoutes = () => {
  return [
    {
      element: <PublicAuth />,
      children: [
        {
          path: "/",
          element: <Login />,
        },
        {
          path: "/otp",
          element: <Otp />,
        },{
          path: "/register",
          element: <Register />,
        },{
          path: "/forgotpassword",
          element: <Forgotpassword />,
        },
        {
          path: "/resetPassword",
          element: <ResetPassword />,
        },
      ],
    },
  ];
};
