import { HashRouter, useRoutes } from "react-router-dom";
import "./App.css";
import { PrivateAuth } from "./Routes/PrivateRoutes";
import { PublicRoutes } from "./Routes/PublicRoutes";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainerMsg } from "./Utils";
function App() {
  const B2BRouting = [...PublicRoutes(), ...PrivateAuth()];
  const B2BRouteConfig = () => useRoutes(B2BRouting);

  return (
    <>
      <HashRouter>
        <B2BRouteConfig />
      </HashRouter>
      <ToastContainerMsg/>
    </>
  );
}

export default App;
