import { Button, Form, Input } from "antd";
import React, { useState } from "react";
import { images } from "../../Assets";
import { setSplashScreen, setUSerDetail } from "../../Store/jetbetslice";
import { NavLink, useNavigate } from "react-router-dom";
import { userLogin } from "../../ApiServices/Apifun";
import { dispatchtoast, setLocalStorageItem } from "../../Utils";
import { useDispatch } from "react-redux";
const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [btnLoading, setBtnLoading] = useState(false);
  const handleFinish = (val) => {
    setBtnLoading(true);
    let data={...val}
    if(data.password===""){
      delete data["password"]
    }
    userLogin(data)
      .then((res) => {
        if (res.status) {
          if (val.password) {
            setLocalStorageItem("accessToken", res.access_token);
            setLocalStorageItem("refreshToken", res.referesh_token);
            dispatch(
              setUSerDetail({
                user: res.user,
                userProfile: res.userProfile,
              })
            );
            dispatch(
              setSplashScreen(true)
            );
            navigate("/active");
          } else {
            navigate("/otp", {
              state: {
                from: "login",
                mobile: val.mobile,
                verificationToken: res.verificationToken,
              },
            });
            dispatchtoast(res.message);
          }
          
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setBtnLoading(false));
  };
  return (
    <>
      <div className="flex bg-black justify-center items-center h-screen">
        <div className=" p-8 m-4 w-[400px] rounded-xl bg-primary">
          <img alt="Logo" src={images.Logo} className="w-44 h-28 m-auto" />
          <p className="text-black  text-center font-semibold text-xl">LogIn</p>
          <div className="form w-full">
            <Form
              onFinish={(val) => handleFinish(val)}
              onFinishFailed={(err) => console.log(err)}
            >
              <div>
                <p className="text-black font-semibold">Mobile</p>
                <Form.Item
                  name="mobile"
                  rules={[
                    {
                      required: true,
                      message: "Enter mobile number!",
                    },
                  ]}
                  className="w-full"
                  style={{ color: "white" }}
                  labelCol={{ span: 24 }}
                >
                  <Input
                    name="mobile"
                    placeholder="Enter mobile number"
                    className="rounded-full mt-1 px-3 border-none hover:border-primary"
                  />
                </Form.Item>
              </div>
              <div>
                <p className="text-black font-semibold">Password</p>
                <Form.Item
                  name="password"
                  style={{ color: "white" }}
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      whitespace: true,
                      message: "Field cannot contain only blank spaces",
                    },
                  ]}
                >
                  <Input.Password
                    name="password"
                    placeholder="Enter password"
                    className="rounded-full mt-1 px-3 border-none hover:border-primary"
                  />
                </Form.Item>
              </div>
              <NavLink to="/forgotpassword">
                <p className="text-black font-semibold cursor-pointer">
                  Forgot Password?
                </p>
              </NavLink>
              <div>
                <Form.Item>
                  <Button
                    loading={btnLoading}
                    htmlType="submit"
                    className="text-primary  hover:border hover:border-white mt-8 w-full rounded-full border-none  transition-all font-semibold bg-white"
                  >
                    Login
                  </Button>
                </Form.Item>
              </div>
              <NavLink to="/register">
                <p className="text-black text-center font-semibold cursor-pointer">
                  Don't have an account? Sign Up
                </p>
              </NavLink>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
