import { Button, Form, Input } from "antd";
import React, { useEffect, useState } from "react";
import { images } from "../../Assets";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { resetPassword } from "../../ApiServices/Apifun";

const ResetPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { mobile, reset_password_token } = location.state || {};
  const [form] = Form.useForm();
  const [btnLoading, setBtnLoading] = useState(false);
  useEffect(()=>{
    if(!location.state){
        navigate(-1)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  const validatePassword = (rule, value) => {
    const { getFieldValue } = form;
    if (value && value !== getFieldValue("password")) {
      return Promise.reject("The password do not match");
    }
    return Promise.resolve();
  };
  const handleFinish = (val) => {
    setBtnLoading(true);
    const payload = {
      mobile,
      token: reset_password_token,
      password: val.password,
    };
    resetPassword(payload)
      .then((res) => {
        if (res.status) {
          navigate("/");
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setBtnLoading(false));
  };
  return (
    <>
      <div className="flex bg-black justify-center items-center h-screen">
        <div className=" p-8 m-4 w-[400px] rounded-xl bg-primary">
          <img alt="Logo" src={images.Logo} className="w-44 h-28 m-auto" />
          <p className="text-black  text-center font-semibold text-xl">
            Reset Password
          </p>
          <div className="form w-full">
            <Form
              form={form}
              initialValues={{
                remember: true,
              }}
              name="basic"
              onFinish={(val) => handleFinish(val)}
              onFinishFailed={(err) => console.log(err)}
            >
              <div>
                <p className="text-black font-semibold">Password</p>
                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Enter mobile number!",
                    },
                  ]}
                  className="w-full"
                  style={{ color: "white" }}
                  labelCol={{ span: 24 }}
                >
                  <Input
                    name="password"
                    placeholder="Enter password"
                    className="rounded-full mt-1 px-3 border-none hover:border-primary"
                  />
                </Form.Item>
              </div>
              <div>
                <p className="text-black font-semibold">Confirm Password</p>
                <Form.Item
                  name="confirm_password"
                  rules={[
                    {
                      required: true,
                      message: "Enter confirm password!",
                    },
                    { validator: validatePassword },
                  ]}
                  className="w-full"
                  style={{ color: "white" }}
                  labelCol={{ span: 24 }}
                >
                  <Input
                    name="confirm_password"
                    placeholder="Enter confirm password"
                    className="rounded-full mt-1 px-3 border-none hover:border-primary"
                  />
                </Form.Item>
              </div>
              <div>
                <Form.Item>
                  <Button
                    loading={btnLoading}
                    htmlType="submit"
                    className="text-primary  hover:border hover:border-white mt-8 w-full rounded-full border-none  transition-all font-semibold bg-white"
                  >
                    Reset Password
                  </Button>
                </Form.Item>
              </div>
              <NavLink to="/">
                <p className="text-black text-center font-semibold cursor-pointer">
                  Go to Login
                </p>
              </NavLink>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
