import { getLocalStorageItem } from "../Utils";
import ApiService from "./Axios";

export const getHeaders = (requireAuth=true) => {
  let headers = {
    'Content-Type' : 'application/json',
  };
  if (requireAuth) {
    const accessToken  = `Bearer ${getLocalStorageItem("accessToken")}`;
    headers.Authorization = `${accessToken}`;
  }
  return headers;
};

export const Get = (url) =>
  ApiService.get(url, { headers: getHeaders() });

export const Post = (url, data,auth) =>
  ApiService.post(url, data, { headers: getHeaders(auth)});

export const Patch = (url, data) =>
  ApiService.patch(url, data, { headers: getHeaders() });

  export const Put = (url, data) =>
  ApiService.put(url, data, { headers: getHeaders() }); 
  
export const Delete = (url) =>
  ApiService.delete(url, { headers: getHeaders() });

export const getAPIUrl = (url, params = "") => {
  return url + `${params}`;
};
export const FormDataPost=(url,data)=>{
  return ApiService.post(url, data, { headers: {
    "Content-Type": "multipart/form-data: boundry=some",
    Authorization:`Bearer ${getLocalStorageItem("accessToken")}`

  } }); 

}
export const FormDataPatch=(url,data)=>{
  return ApiService.patch(url, data, { headers: {
    "Content-Type": "multipart/form-data",
  } }); 

}
export const getErrors = (error) => {
  const errorData = error.response.data.error;
  const errors = {};
  Object.keys(errorData).forEach((key) => {
    errors[key] = errorData[key];
  });
  return errors;
};
