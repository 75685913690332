import { Button, Form, Input } from "antd";
import React, { useState } from "react";
import { images } from "../../Assets";
import { NavLink, useNavigate } from "react-router-dom";
import { userRegister } from "../../ApiServices/Apifun";
import { dispatchtoast } from "../../Utils";

const Register = () => {
  const navigate = useNavigate();
  const [btnLoading, setBtnLoading] = useState(false);
  const [form] = Form.useForm();
  const validatePassword = (rule, value) => {
    const { getFieldValue } = form;
    if (value && value !== getFieldValue("password")) {
      return Promise.reject("The password do not match");
    }
    return Promise.resolve();
  };
  const handleFinish = (val) => {
    setBtnLoading(true);
    userRegister(val)
      .then((res) => {
        if (res.status) {
          navigate("/otp", {
            state: {
              from: "login",
              mobile: val.mobile,
              verificationToken: res.verificationToken,
            },
          });
          dispatchtoast(res.message);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setBtnLoading(false));
  };
  return (
    <>
      <div className="flex bg-black justify-center items-center h-screen">
        <div className=" p-8 w-[500px] m-4 rounded-xl bg-primary">
          <img alt="Logo" src={images.Logo} className="w-44 h-28 m-auto" />
          <p className="text-black  text-center font-semibold text-xl">
            Register
          </p>
          <div className="form w-full">
            <Form
              form={form}
              onFinish={(val) => handleFinish(val)}
              onFinishFailed={(err) => console.log(err)}
            >
              <div className="grid  grid-cols-1 sm:grid-cols-2 mt-4 gap-x-6 place-items-center">
                <div className="w-full">
                  <p className="text-black font-semibold">Mobile</p>
                  <Form.Item
                    name="mobile"
                    rules={[
                      {
                        required: true,
                        message: "Enter mobile number!",
                      },
                    ]}
                    style={{ color: "white" }}
                    labelCol={{ span: 24 }}
                  >
                    <Input
                      name="mobile"
                      placeholder="Enter mobile number"
                      className="rounded-full mt-1 px-3 border-none hover:border-primary"
                    />
                  </Form.Item>
                </div>
                <div className="w-full">
                  <p className="text-black font-semibold">Name</p>
                  <Form.Item
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: "Enter user name!",
                      },
                    ]}
                    className="w-full"
                    style={{ color: "white" }}
                    labelCol={{ span: 24 }}
                  >
                    <Input
                      name="name"
                      placeholder="Enter user name"
                      className="rounded-full mt-1 px-3 border-none hover:border-primary"
                    />
                  </Form.Item>
                </div>{" "}
                {/* <div className="w-full">
                  <p className="text-black font-semibold">Email</p>
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        required: true,
                        message: "Enter email!",
                      },
                    ]}
                    className="w-full"
                    style={{ color: "white" }}
                    labelCol={{ span: 24 }}
                  >
                    <Input
                      name="email"
                      placeholder="Enter email"
                      className="rounded-full mt-1 px-3 border-none hover:border-primary"
                    />
                  </Form.Item>
                </div> */}
                <div className="w-full">
                  <p className="text-black font-semibold">Password</p>
                  <Form.Item
                    name="password"
                    style={{ color: "white" }}
                    labelCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: "Enter password!",
                      },
                    ]}
                  >
                    <Input.Password
                      name="password"
                      placeholder="Enter password"
                      className="rounded-full mt-1 px-3 border-none hover:border-primary"
                    />
                  </Form.Item>
                </div>
                <div className="w-full">
                  <p className="text-black font-semibold">Confirm Password</p>
                  <Form.Item
                    name="confirm_password"
                    style={{ color: "white" }}
                    dependencies={["password"]}
                    labelCol={{ span: 24 }}
                    rules={[
                      {
                        required: true,
                        message: "Enter confirm password!",
                      },
                      { validator: validatePassword },
                    ]}
                  >
                    <Input.Password
                      name="confirm_password"
                      placeholder="Enter confirm password"
                      className="rounded-full mt-1 px-3 border-none hover:border-primary"
                    />
                  </Form.Item>
                </div>
              </div>
              <div>
                <Form.Item>
                  <Button
                    loading={btnLoading}
                    htmlType="submit"
                    className="text-primary  hover:border hover:border-white mt-4 w-full rounded-full border-none  transition-all font-semibold bg-white"
                  >
                    Register
                  </Button>
                </Form.Item>
              </div>
              <NavLink to="/">
                <p className="text-black text-center font-semibold cursor-pointer">
                  Go to Login
                </p>
              </NavLink>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
