import React, {  useState } from "react";
import { Button, Form, Input } from "antd";
import { dispatchtoast } from "../../../Utils";
import {  UpdateProfile } from "../../../ApiServices/Apifun";

const EditProfile = ({getProfileDetail}) => {
  const [btnLoading, setBtnLoading] = useState(false);

  const [form] = Form.useForm();

  const handleFinish = (val) => {
    setBtnLoading(true);
    UpdateProfile({name:val.name})
      .then((res) => {
        if (res.status) {
          getProfileDetail()
          dispatchtoast(res.message);
          form.resetFields();
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setBtnLoading(false));
  };


  return (
    <div className="w-full mt-3 bg-white p-6 rounded-lg  ">
      <Form
        form={form}
        name="basic"
        initialValues={{
          remember: true,
        }}
        onFinishFailed={(err) => console.log(err)}
        onFinish={(val) => handleFinish(val)}
      >
        <div className="grid grid-cols-1 sm:grid-cols-1 gap-4 place-items-start">
          <Form.Item
            name={`name`}
            label={"Name"}
            className="w-full md:w-1/2"
            labelCol={{ span: 24 }}
            rules={[
              {
                required: true,
                message: "Enter name!",
              },
              {
                whitespace: true,
                message: "Field cannot contain only blank spaces",
              },
            ]}
          >
            <Input
              placeholder="Enter Name"
              name="name"
              className="outline-none text-xs w-full rounded-none border   border-gray-200 focus:border-primary  h-9 px-2 "
            />
          </Form.Item>
          {/* <Form.Item
          name={`email`}
          label={"Email"}
          className="w-full"
          labelCol={{ span: 24 }}
          rules={[
            {
              whitespace: true,
              message: "Field cannot contain only blank spaces",
            },
          ]}
        >
          <Input
            placeholder="Enter email"
            name="email"
            className="outline-none text-xs rounded-none border  w-full border-gray-200 focus:border-primary  h-9 px-2 "
          />
        </Form.Item> */}
          <Form.Item className="w-full md:w-1/2">
            <Button
              loading={btnLoading}
              className="w-full font-semibold h-10 bg-primary hover:bg-primaryhover text-white mt-2 border-none"
              htmlType="submit"
            >
              Update
            </Button>
          </Form.Item>{" "}
        </div>
      </Form>
    </div>
  );
};

export default EditProfile;
