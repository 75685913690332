import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { getLocalStorageItem } from "../Utils";
import Layout from "../Layout";

function ProtectedAuth() {
  const isAuthenticated = () => {
    const user = getLocalStorageItem("accessToken");
    return user;
  };

  return isAuthenticated() ? (
    <>
      {" "}
      <Layout>
        <Outlet />
      </Layout>
    </>
  ) : (
    <>
      <Navigate to="/" replace />
    </>
  );
}
export default ProtectedAuth;
