import { createSlice } from "@reduxjs/toolkit";
// import { getLocalStorageItem } from "../Utils";

const initialState = {
  userDetails:{},
  selectedTab:null,
  profilePicture:null,
  splashscreen:false
};

export const jatbetslice = createSlice({
  name: "jatbet",
  initialState,
  reducers: {
    setUSerDetail: (state, action) => {
      state.userDetails = action.payload;
    },
    setProfilePic: (state, action) => {
      state.profilePicture = action.payload;
    },
    setSelectedTab: (state, action) => {
      state.selectedTab = action.payload;
    },
    setSplashScreen: (state, action) => {
      state.splashscreen = action.payload;
    },
    clearAllData:(state)=>{
      state.splashscreen = null;
      state.profilePicture = null;
      state.userDetails = {};
      state.selectedTab = null;
    }
  },
});

// Action creators are generated for each case reducer function
export const {
  setUSerDetail,setSelectedTab,setSplashScreen,clearAllData,setProfilePic
} = jatbetslice.actions;

export default jatbetslice.reducer;
