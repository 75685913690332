import React, { useEffect, useState } from "react";
import { getPoolList } from "../../../ApiServices/Apifun";
import { Button, Pagination, Spin } from "antd";
import ResultModal from "./ResultModal";
import { useDispatch } from "react-redux";
import { setSelectedTab } from "../../../Store/jetbetslice";

const Completd = () => {
  const [completedQuiz, setcompletedQuiz] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [spinner, setSpinner] = useState(false);
  const [poolId, setPoolId] = useState(null);
  const [totalPages, setTotalPages] = useState(null);
  const getALlCompleted = (page) => {
    setSpinner(true);
    getPoolList(`?type=pool_completed&role=user&page=${page}`)
      .then((res) => {
        if (res.status) {
          setcompletedQuiz(res.data);
          setCurrentPage(res.currentPage);
          setTotalPages(res.totalRecords);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setSpinner(false));
  };
  useEffect(() => {
    dispatch(setSelectedTab(2));
    getALlCompleted(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);


  return (
    <>
      <Spin spinning={spinner}>
        <div className="bg-primary rounded-xl  p-4">
          <div className="h-[55vh] md:h-[65vh] overflow-y-scroll p-4">
            {completedQuiz.map((i, index) => {
              return (
                <div
                  key={index}
                  className="grid  grid-cols-2 sm:grid-cols-3 text-xs md:grid-cols-5 gap-y-4 place-items-start md:place-items-center place-content-center gap-x-4 px-4 font-semibold bg-white text-black my-4 py-2 rounded-md "
                >
                  <p className="bg-primary  rounded-full text-white w-8 h-8 flex justify-center items-center">
                    {index + 1}
                  </p>
                  <p>{i.subject}</p>
                  <div>
                    <p>Start At:</p>
                    <p>{new Date(i.pool_start_time).toLocaleString()}</p>
                  </div>
                  <div>
                    <p>Ends At:</p>
                    <p>{new Date(i.pool_end_time).toLocaleString()}</p>
                  </div>
                  <Button
                    onClick={() =>
                      i.result_declared||i.pool_end_time<Date.now()
                        ? (setPoolId(i._id), setOpenModal(true))
                        : {}
                    }
                    style={{ color: "white" }}
                    className={`${
                      i.result_declared||i.pool_end_time<Date.now()
                        ? "bg-green-600"
                        : "bg-red-600"
                    } border-none text-white font-semibold`}
                  >
                    {i.result_declared||i.pool_end_time<Date.now() ? "Result" : "Pending"}
                  </Button>
                </div>
              );
            })}
          </div>
        </div>
      </Spin>
      <div className="flex mt-2 justify-end items-center">
        <Pagination
          onChange={(page) => getALlCompleted(page)}
          current={currentPage}
          total={totalPages}
        />
      </div>
      {openModal && (
        <ResultModal isOpen={openModal} setIsOpen={setOpenModal} id={poolId} />
      )}
    </>
  );
};

export default Completd;
