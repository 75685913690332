import React, { useEffect, useState } from "react";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import { FaThumbsUp } from "react-icons/fa";
import { AiFillCheckCircle } from "react-icons/ai";
import { dispatchtoast} from "../../../Utils";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedTab } from "../../../Store/jetbetslice";
import { useLocation, useNavigate } from "react-router-dom";
import { getQuestions, saveQuizDetail } from "../../../ApiServices/Apifun";
import { Button, Spin } from "antd";
const QuizPage = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const userDetail = useSelector((state) => state.jatbet.userDetails);
  const [spinner, setSpinner] = useState(false);
  const [Question, setQuestion] = useState([]);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [selectedAnswers, setSelectedanswers] = useState({});
  const { id } = location.state || {};
  useEffect(() => {
    setSpinner(true);
    if (!location.state) {
      navigate(-1);
    }
    dispatch(setSelectedTab(1));
    getQuestions(`?pool_id=${id}&role=user`)
      .then((res) => {
        if (res.status) {
          setQuestion(res.question);
          setSpinner(false);
        }
      })
      .catch((err) => console.log(err))
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleAnswer = (questionId, id) => {
    setSelectedanswers({ ...selectedAnswers, [questionId]: id });
  };
  const handleSaveAnswers = () => {
    setSpinner(true);
    let payload = {
      user_id: userDetail.user.userId,
      pool_id: id,
      bet_details: Object.entries(selectedAnswers).map(([key, val]) => {
        return {
          question_id: key,
          choose_option: selectedAnswers[key].toString(),
        };
      }),
    };
    if (Object.keys(selectedAnswers).length === Question.length) {
      saveQuizDetail(payload)
        .then((res) => {
          if (res.status) {
            dispatchtoast("Answer Submitted successfully!");
            navigate("/active");
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setSpinner(false));
    } else {
      dispatchtoast("You can't submit without selecting all answers", true);
      setSpinner(false);
    }
  };
  return (
    <>
      <Spin spinning={spinner}>
        <div className="flex justify-center items-center h-full">
          <div className="bg-primary w-full md:w-[80%] p-8 md:p-16 rounded-xl">
            <p className="text-white font-semibold">
              Question {questionIndex + 1} out of {Question.length}
            </p>
            <div className="grid grid-cols-1 md:grid-cols-2 place-items-center items-center">
              <p className="text-white font-bold mt-2 md:mt-0 text-sm md:text-xl">
                {Question.length !== 0 ? Question[questionIndex].question : []}
              </p>
              <div className="max-h-[200px] overflow-y-scroll px-4">
                {Question.length !== 0 &&
                  Question[questionIndex].options?.map((i, index) => {
                    return (
                      <div
                        onClick={() =>
                          handleAnswer(
                            Question[questionIndex]["_id"],
                            i.option_id
                          )
                        }
                        className="flex w-[200px]  justify-between gap-4 items-center bg-white my-6 rounded-full  gap-x-3 px-2 md:px-3 py-0 md:py-0 font-bold cursor-pointer"
                      >
                        <p className="text-primary text-sm md:text-xs">
                          {i.option_value}
                        </p>
                        {selectedAnswers[Question[questionIndex]["_id"]] ===
                        i.option_id ? (
                          <AiFillCheckCircle
                            className="text-green-800"
                            size={20}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className="flex justify-end gap-4 mt-4 items-center">
              <Button
                style={{ color: "white" }}
                disabled={questionIndex === 0}
                onClick={() => setQuestionIndex((prev) => prev - 1)}
                className="bg-blue-900 border-none h-10 text-3xl w-10 flex justify-center items-center hover:bg-blue-700 hover:scale-125 transition-all p-2  cursor-pointer text-white rounded-full "
              >
                <BiChevronLeft size={30} className="text-3xl" />
              </Button>
              {questionIndex < Question.length - 1 ? (
                <Button
                  style={{ color: "white" }}
                  onClick={() => setQuestionIndex((prev) => prev + 1)}
                  className="bg-blue-900 border-none h-10 w-10 flex justify-center items-center p-2 hover:bg-blue-700 hover:scale-125 transition-all  cursor-pointer text-white rounded-full "
                >
                  <BiChevronRight size={20} />
                </Button>
              ) : (
                <Button
                  onClick={handleSaveAnswers}
                  style={{ color: "white" }}
                  className="bg-green-900 border-none h-10 w-10 flex justify-center items-center p-2 hover:bg-green-700 hover:scale-125 transition-all  cursor-pointer text-white rounded-full "
                >
                  <FaThumbsUp size={20} />
                </Button>
              )}
            </div>
          </div>
        </div>
      </Spin>
    </>
  );
};

export default QuizPage;
