import { Button, Form, Input } from "antd";
import React, { useEffect, useState } from "react";
import { images } from "../../Assets";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import {
  ResnedOTP,
  verifyOtpafterForgot,
  verifyOtpafterLogin,
} from "../../ApiServices/Apifun";
import { dispatchtoast, setLocalStorageItem } from "../../Utils";
import { useDispatch } from "react-redux";
import { setSplashScreen, setUSerDetail } from "../../Store/jetbetslice";

const Otp = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [timer, setTimer] = useState(60);
  const [btnLoading, setBtnLoading] = useState(false);
  const { from, mobile, verificationToken } = location.state || {};
  const [token,setToken]=useState(verificationToken)
  const handleFinish = (val) => {
    setBtnLoading(true);
    let payload = {
      otp: val.otp,
      mobile,
      verificationToken:token,
    };
    if (from === "forgot") {
      verifyOtpafterForgot(payload)
        .then((res) => {
          if (res.status) {
            dispatchtoast(res.message);
            navigate("/resetPassword", {
              state: {
                mobile: mobile,
                reset_password_token: res.reset_password_token,
              },
            });
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setBtnLoading(false));
    } else {
      verifyOtpafterLogin(payload)
        .then((res) => {
          if (res.status) {
            setLocalStorageItem("accessToken", res.access_token);
            setLocalStorageItem("refreshToken", res.referesh_token);
            dispatch(
              setUSerDetail({ user: res.user, userProfile: res.userProfile })
            );
            dispatch(
              setSplashScreen(true)
            );
            navigate("/active");
          }
        })
        .catch((err) => console.log(err))
        .finally(() => setBtnLoading(false));
    }
  };
  useEffect(() => {
    if(!location.state){
        navigate(-1)
    }
    let a = 60;
    let timerinterval = setInterval(() => {
      a = a - 1;
      if (a === 0) {
        clearInterval(timerinterval);
      }
      setTimer(a);
    }, 1000);
    return ()=>clearInterval(timerinterval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleResend = () => {
    let payload = {
      mobile,
      verificationToken:token,
    };
    ResnedOTP(payload)
      .then((res) => {
        if(res.status){
          setToken(res.verificationToken)
            dispatchtoast(res.message)
        }
      })
      .catch((err) => {
        navigate("/")
      });
  };
  return (
    <>
      <div className="flex bg-black justify-center items-center h-screen">
        <div className=" p-8 m-4 w-[400px] rounded-xl bg-primary">
          <img alt="Logo" src={images.Logo} className="w-44 h-28 m-auto" />
          <p className="text-black  text-center font-semibold text-xl">OTP</p>
          <div className="form w-full">
            <Form
              onFinish={(val) => handleFinish(val)}
              onFinishFailed={(err) => console.log(err)}
            >
              <div>
                <p className="text-black font-semibold">OTP</p>
                <Form.Item
                  name="otp"
                  rules={[
                    {
                      required: true,
                      message: "Enter OTP!",
                    },
                  ]}
                  className="w-full"
                  style={{ color: "white" }}
                  labelCol={{ span: 24 }}
                >
                  <Input
                    name="otp"
                    maxLength={6}
                    onInput={(e) => {
                      const value = e.target.value.replace(/\D/g, "");
                      e.target.value = value;
                    }}
                    placeholder="Enter 6 digit OTP"
                    className="rounded-full mt-1 px-3 border-none hover:border-primary"
                  />
                </Form.Item>
              </div>
              {timer > 0 ? (
                <p className="text-end font-semibold text-black cursor-pointer">
                  Resend OTP in {timer} sec.
                </p>
              ) : (
                <p
                  onClick={() => handleResend()}
                  className="text-end font-semibold text-black cursor-pointer"
                >
                  Resend OTP.
                </p>
              )}
              <div>
                <Form.Item>
                  <Button
                    loading={btnLoading}
                    htmlType="submit"
                    className="text-primary  hover:border hover:border-white mt-8 w-full rounded-full border-none  transition-all font-semibold bg-white"
                  >
                    Verify OTP
                  </Button>
                </Form.Item>
              </div>
              <NavLink to="/register"></NavLink>
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Otp;
