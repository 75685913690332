import { ToastContainer, toast } from "react-toastify";

export const getLocalStorageItem = (key) => {
    try {
      const item = localStorage.getItem(key);
      return item ? JSON.parse(item) : null;
    } catch (error) {
      console.error(`Error getting ${key} from local storage: ${error}`);
      return null;
    }
  };
  
  // Set an item in local storage
  export const setLocalStorageItem = (key, value) => {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.error(`Error setting ${key} in local storage: ${error}`);
    }
  };
  
  // Remove an item from local storage
  export const removeLocalStorageItem = (key) => {
    try {
      localStorage.removeItem(key);
    } catch (error) {
      console.error(`Error removing ${key} from local storage: ${error}`);
    }
  };
  export const dispatchtoast = (message, type) => {
    if (type) {
      return toast.error(message);
    }
    return toast.success(message);
  };
  export const ToastContainerMsg = () => {
    return (
      <ToastContainer
        style={{
          fontSize: "12px",
        }}
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        // draggable
        // transition="flip"
        pauseOnHover
        theme="colored"
      />
    );
  };
  export const showError = (error) => {
    if (typeof error === "object") {
      Object.entries(error).map(([key, value]) => {
        const errmessage = `${key}: ${value}`;
        return dispatchtoast(errmessage, true);
      });
    } else {
      return dispatchtoast(error, true);
    }
  };