import React, { useEffect, useState } from 'react'
import { getBannerImage } from '../../../ApiServices/Apifun'
import { useDispatch } from 'react-redux';
import { setSelectedTab } from '../../../Store/jetbetslice';
import { Spin } from 'antd';

const Rewards = () => {
    const dispatch = useDispatch();
    const [images,setImages]=useState([])
    const[loader,setLoader]=useState(false)
    useEffect(() => {
      setLoader(true)
        dispatch(setSelectedTab(4));
        getBannerImage().then((res)=>{
          if(res.status){
            setImages(res.data)
          }
        }).catch(err=>console.log(err)).finally(()=>setLoader(false))
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
  return (
    <>
    <Spin className='flex justify-center items-center h-full' spinning={loader}>
    {
      images.map((i)=><img className='w-full mt-2 rounded-2xl' crossOrigin='anonymous' src={`https://api.joc.today/uploads/rewards/${i.image}`}/>)
    }
    </Spin>
    </>
  )
}

export default Rewards