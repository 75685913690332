import { Pagination, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { BiChevronRight } from "react-icons/bi";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setSelectedTab } from "../../../Store/jetbetslice";
import { getPoolList } from "../../../ApiServices/Apifun";
const Active = () => {
  const dispatch = useDispatch();
  const [activeQuiz, setActivequiz] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [spinner, setSpinner] = useState(false);
  const [totalPages, setTotalPages] = useState(null);
  const getALlCompleted = (page) => {
    setSpinner(true);
    getPoolList(`?type=active&role=user&page=${page}`)
      .then((res) => {
        if (res.status) {
          setActivequiz(res.data);
          setCurrentPage(res.currentPage);
          setTotalPages(res.totalRecords);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setSpinner(false));
  };
  useEffect(() => {
    dispatch(setSelectedTab(1));
    getALlCompleted(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
     <Spin spinning={spinner}>
     <div className="bg-primary rounded-xl  p-4">
        <div className="h-[55vh] md:h-[65vh] overflow-y-scroll p-4">
          {activeQuiz.length!==0?activeQuiz.map((i, index) => {
            return (
              <div
                key={index}
                className="grid grid-cols-2 text-xs sm:grid-cols-3 md:grid-cols-5 gap-y-4 place-items-start md:place-items-center place-content-center gap-x-4 px-4 font-semibold bg-white text-black my-4 py-2 rounded-md "
              >
                <p className="bg-primary  rounded-full text-white w-8 h-8 flex justify-center items-center">
                  {index + 1}
                </p>
                <p>{i.subject}</p>
                <div>
                  <p>Start At:</p>
                  <p>{new Date(i.pool_start_time).toLocaleString()}</p>
                </div>
                <div>
                  <p>Ends At:</p>
                  <p>{new Date(i.pool_end_time).toLocaleString()}</p>
                </div>
                <NavLink to="/quiz-page"state={{id:i._id}}>
                  <p className="bg-primary  rounded-full text-white w-8 h-8 flex justify-center items-center opacity-70 cursor-pointer">
                    <BiChevronRight size={30} />
                  </p>
                </NavLink>
              </div>
            );
          }):<p className="flex justify-center items-center text-white h-full font-semibold">There is no active quiz!</p>}
        </div>
      </div>
     </Spin>
    {activeQuiz.length!==0?  <div className="flex mt-2 justify-end items-center">
        <Pagination
          onChange={(page) => getALlCompleted(page)}
          current={currentPage}
          total={totalPages}
        />
      </div>:null}
    </>
  );
};

export default Active;
