import { Dropdown } from "antd";
import React, { useEffect, useState } from "react";
import {
  AiFillGift,
  AiFillHome,
  AiOutlineBell,
  AiOutlineUser,
} from "react-icons/ai";
import { BiMedal, BiSolidUser } from "react-icons/bi";
import { RxHamburgerMenu } from "react-icons/rx";

import { IoCheckmarkDoneCircle } from "react-icons/io5";

import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { images } from "../Assets";
import { clearAllData, setProfilePic, setSplashScreen } from "../Store/jetbetslice";
import { ProfileDetail } from "../ApiServices/Apifun";
const Layout = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [windowwidth, setwindowwidth] = useState(window.innerWidth);
  const selectedTab = useSelector((state) => state.jatbet.selectedTab);
  const profilePic = useSelector((state) => state.jatbet.profilePicture);
  const splashscreen = useSelector((state) => state.jatbet.splashscreen);
  useEffect(() => {
    const handleResize = () => {
      setwindowwidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    // setselectedTab(getLocalStorageItem("selectedTab"));
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const items = [
    {
      key: "1",
      label: (
        <div className="w-48">
          <div className="text-sm flex justify-start items-center"></div>
          <NavLink to={"/setting"}>
            <p className="font-bold text-[16px] mb-4">Settings</p>
          </NavLink>
          <hr />
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <p
          onClick={() => {
            dispatch(clearAllData())
            localStorage.clear();
            navigate("/");
          }}
          className="font-medium"
        >
          Sign Out
        </p>
      ),
    },
  ];
  const getProfileDetail = () => {
    ProfileDetail()
      .then((res) => {
        if (res.data.profile_image !== null) {
          dispatch(
            setProfilePic(
              "https://api.joc.today" +
                "/uploads/userProfile/" +
                res.data.profile_image
            )
          );
        }
      })
      .catch((err) => console.log(err));
  };
  useEffect(() => {
    getProfileDetail();
    setTimeout(() => {
      dispatch(setSplashScreen(false));
    }, 5000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {}, [selectedTab]);
  return (
    <>
      {splashscreen && windowwidth < 768 ? (
        <img
          src={images.splash}
          alt="splash"
          className="w-full"
          style={{ height: window.innerHeight }}
        />
      ) : (
        <>
          <div className="bg-primary md:top-0 md:hidden fixed z-10 h-20  w-full  justify-between items-center">
            <div className="flex md:hidden text-white font-semibold gap-8  justify-between items-center">
              <div className="flex ml-10  justify-around items-center">
                <Dropdown
                  className="dropdownMenu cursor-pointer "
                  menu={{ items }}
                  trigger={["click"]}
                >
                  {profilePic ? (
                   <div  className="relative">
                     <img
                      src={profilePic}
                      alt="userImg"
                      crossOrigin="anonymous"
                      className="rounded-full border-4 border-primary w-12 h-12"
                    />
                    <p className="absolute bg-white rounded-full p-1 -right-1 -bottom-0 ">
                    <RxHamburgerMenu color="black" size={10}/>
                    </p>
                   </div>
                  ) : (
                   <div className="relative">
                     <p className="bg-white p-3 rounded-full">
                      <BiSolidUser color="gray"/>
                    </p>
                    <p className="absolute bg-white rounded-full p-1 -right-1 -bottom-1 ">
                    <RxHamburgerMenu color="black" size={10}/>
                    </p>
                   </div>
                  )}
                </Dropdown>
              </div>
              <div className="flex justify-center items-center">
              <img src={images.Logo} alt="WebLogo" className="w-auto h-20 " />
              </div>
            </div>
          </div>

          <div className="bg-primary  bottom-0 fixed md:hidden z-10 h-20  w-full flex justify-around items-center">
            <NavLink to="/active">
              <div
                className={`flex flex-col ${
                  selectedTab === 1 ? "bg-black p-2 rounded-md text-white" : ""
                } justify-center items-center`}
              >
                <p className="cursor-pointer hover:text-yellow-500 transition-all">
                  <AiFillHome color={"white"} size={20} />
                </p>
                <p className={`cursor-pointer text-white   transition-all`}>
                  Active
                </p>
              </div>
            </NavLink>
            <NavLink to="/completed">
              <div
                className={`flex flex-col ${
                  selectedTab === 2 ? "bg-black p-2 rounded-md text-white" : ""
                } justify-center items-center`}
              >
                <p className="cursor-pointer hover:text-black transition-all">
                  <IoCheckmarkDoneCircle color={"white"} size={20} />
                </p>
                <p className={`cursor-pointer text-white   transition-all`}>
                  Completed
                </p>
              </div>
            </NavLink>
          <NavLink to={"/rewards"}>
          <div
              className={`flex flex-col ${
                selectedTab === 4 ? "bg-black p-2 rounded-md text-white" : ""
              } justify-center items-center`}
            >
              <p className="cursor-pointer hover:text-black transition-all">
                <AiFillGift color="white" size={20} />
              </p>
              <p className={`cursor-pointer text-white   transition-all`}>
                Rewards
              </p>
            </div>
          </NavLink>
            <NavLink to="/my-quiz">
              <div
                className={`flex flex-col ${
                  selectedTab === 3 ? "bg-black p-2 rounded-md text-white" : ""
                } justify-center items-center`}
              >
                <p className="cursor-pointer hover:text-black transition-all">
                  <BiMedal color={"white"} size={20} />
                </p>
                <p className={`cursor-pointer text-white   transition-all`}>
                  My Quiz
                </p>
              </div>{" "}
            </NavLink>
          </div>

          <div className="bg-primary top-0  fixed z-10 h-20  w-full hidden md:flex justify-around items-center">
          <div className="flex justify-center items-center">
              <img src={images.Logo} alt="WebLogo" className="w-auto h-20 " />
              </div>
            <div className="flex  text-white font-semibold gap-16 justify-around items-center">
              <NavLink to="/active">
                <p
                  className={`cursor-pointer ${
                    selectedTab === 1
                      ? "bg-black text-yellow-500 p-2 px-3 rounded-md"
                      : ""
                  } hover:text-yellow-500   transition-all`}
                >
                  Active
                </p>
              </NavLink>
              <NavLink to="/completed">
                <p
                  className={`cursor-pointer ${
                    selectedTab === 2
                      ? "bg-black text-yellow-500 p-2 px-3 rounded-md"
                      : ""
                  } hover:text-yellow-500 transition-all`}
                >
                  Completed Quiz
                </p>
              </NavLink>
              <NavLink to="/rewards">
                <p
                  className={`cursor-pointer ${
                    selectedTab === 4
                      ? "bg-black text-yellow-500 p-2 px-3 rounded-md"
                      : ""
                  } hover:text-yellow-500 transition-all`}
                >
                 Rewards
                </p>
              </NavLink>
              <NavLink to="/my-quiz">
                <p
                  className={`cursor-pointer ${
                    selectedTab === 3
                      ? "bg-black text-yellow-500 p-2 px-3 rounded-md"
                      : ""
                  } hover:text-yellow-500 transition-all`}
                >
                  My Quiz
                </p>
              </NavLink>
            </div>
            <Dropdown
              className="dropdownMenu cursor-pointer "
              menu={{ items }}
              trigger={["click"]}
            >
              {!profilePic ? (
                <p className="bg-gray-500 p-3 rounded-full">
                  <AiOutlineUser />
                </p>
              ) : (
                <div  className="relative">
                     <img
                      src={profilePic}
                      alt="userImg"
                      crossOrigin="anonymous"
                      className="rounded-full border-4 border-primary w-12 h-12"
                    />
                    <p className="absolute bg-white rounded-full p-1 -right-0 -bottom-0 ">
                    <RxHamburgerMenu color="black" size={10}/>
                    </p>
                   </div>
              )}
            </Dropdown>
          </div>
          <div className="bg-black px-2 md:px-16 py-24 md:py-16 min-h-screen  pt-28 md:pt-32">
            {children}
          </div>
        </>
      )}
    </>
  );
};

export default Layout;
