import React, { useState } from "react";
import { Button, Form, Input } from "antd";
import { dispatchtoast } from "../../../Utils";
import { changePassword } from "../../../ApiServices/Apifun";

const ChangePassword = () => {
  const [btnLoading, setBtnLoading] = useState(false);
  const [form] = Form.useForm();
  const validatePassword = (rule, value) => {
    const { getFieldValue } = form;
    if (value && value !== getFieldValue("new_password")) {
      return Promise.reject("The passwords do not match!");
    }
    return Promise.resolve();
  };
  const handleFinish = (val) => {
    setBtnLoading(true);
    let data = { ...val };
    // data.old_password = bcrypt.hashSync(
    //   data.old_password,
    //   "$2a$10$CwTycUXWue0Thq9StjUM0u"
    // ); // hash created previously created upon sign up
    // data.new_password = bcrypt.hashSync(
    //   data.new_password,
    //   "$2a$10$CwTycUXWue0Thq9StjUM0u"
    // ); // hash created previously created upon sign up

    delete data["confirm_new_password"];
    changePassword(data)
      .then((res) => {
        if (res.status) {
          dispatchtoast(res.message);
          form.resetFields();
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setBtnLoading(false));
  };
  return (
    <div className="w-full mt-3 bg-white p-6 rounded-lg  ">
      <Form
        form={form}
        name="basic"
        initialValues={{
          remember: true,
        }}
        onFinishFailed={(err) => console.log(err)}
        onFinish={(val) => handleFinish(val)}
      >
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 place-items-center">
        <Form.Item
          name={`mobile`}
          label={"Mobile"}
          className="w-full"
          labelCol={{ span: 24 }}
          rules={[
            {
              required: true,
              message: "Enter mobile number!",
            },
            {
              whitespace: true,
              message: "Field cannot contain only blank spaces",
            },
          ]}
        >
          <Input
            placeholder="Enter Mobile"
            name="old_password"
            onInput={(e)=>{
                const value = e.target.value.replace(/\D/g, '');
                e.target.value = value;
              }}
            className="outline-none text-xs w-full rounded-none border   border-gray-200 focus:border-primary  h-9 px-2 "
          />
        </Form.Item>
        <Form.Item
          name={`old_password`}
          label={"Old Password"}
          className="w-full"
          labelCol={{ span: 24 }}
          rules={[
            {
              required: true,
              message: "Enter old password!",
            },
            {
              whitespace: true,
              message: "Field cannot contain only blank spaces",
            },
          ]}
        >
          <Input.Password
            placeholder="Enter old password"
            name="old_password"
            className="outline-none text-xs rounded-none border  w-full border-gray-200 focus:border-primary  h-9 px-2 "
          />
        </Form.Item>
        <Form.Item
          name={`new_password`}
          label={"New password"}
          className="w-full"
          labelCol={{ span: 24 }}
          rules={[
            {
              required: true,
              message: "Enter new password!",
            },
            {
              whitespace: true,
              message: "Field cannot contain only blank spaces",
            },
          ]}
        >
          <Input.Password
            placeholder="Enter new password"
            name="new_password"
            className="outline-none text-xs rounded-none border  w-full border-gray-200 focus:border-primary  h-9 px-2 "
          />
        </Form.Item>{" "}
        <Form.Item
          name={`confirm_new_password`}
          label={"Confirm Password"}
          labelCol={{ span: 24 }}
          className="w-full"
          dependencies={["new_password"]}
          rules={[
            {
              required: true,
              message: "Please confirm your password!",
            },
            { validator: validatePassword },
            {
              whitespace: true,
              message: "Field cannot contain only blank spaces",
            },
          ]}
        >
          <Input.Password
            placeholder="Confirm your password"
            name="confirm_new_password"
            className="outline-none text-xs rounded-none border  w-full border-gray-200 focus:border-primary  h-9 px-2 "
          />
        </Form.Item>{" "}
        <Form.Item className="w-full">
          <Button
            loading={btnLoading}
            className="w-full font-semibold h-10 bg-primary hover:bg-primaryhover text-white mt-2 border-none"
            htmlType="submit"
          >
            Save
          </Button>
        </Form.Item>{" "}
        </div>
      
      </Form>
    </div>
  );
};

export default ChangePassword;
