export const Authendpoint = {
  Login: "auth/login-mobile",
  VerifyOtpafterLogin: "auth/verify-otp",
  register: "auth/register",
  forgotPassword: "auth/forgot-password",
  verifyOtpafterForgot: "auth/forget-password-otp-verify",
  resetPassword: "auth/reset-password",
  resendOTP: "auth/resend-otp",
};
export const Globalendpoints = {
  pools: "pool/pool",
  quizRank: "pool/rank",
  GetQuizQuestion: "pool/questions",
  saveQuizDetail: "user/save-quiz-details",
  changePassword: "auth/change-password",
  updateProfile:"user/update-profile",
  ProfileDetail:"user/user-profile",
  getImages:"user/reward-list"
};
