import Active from "../Views/Main/Active";
import QuizPage from "../Views/Main/Active/QuizPage";
import Completd from "../Views/Main/Completd/Completd";
import MyQuiz from "../Views/Main/MyQuiz/MyQuiz";
import Rewards from "../Views/Main/Rewards/Rewards";
import Settings from "../Views/Main/Settings/Settings";

import ProtectedAuth from "./PrivateAuth";

export const PrivateAuth = () => {
  return [
    {
      element: <ProtectedAuth />,
      children: [
        {
          path: "/active",
          element: <Active />,
        },
        {
          path: "/completed",
          element: <Completd />,
        }, {
          path: "/my-quiz",
          element: <MyQuiz />,
        },
        {
          path: "/quiz-page",
          element: <QuizPage />,
        },
        {
          path: "/setting",
          element: <Settings />,
        },
        {
          path: "/rewards",
          element: <Rewards />,
        },
      ],
    },
  ];
};
