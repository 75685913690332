import React, { useEffect, useRef } from "react";
import { useState } from "react";
import { MdOutlinePassword } from "react-icons/md";
import { AiOutlineUser } from "react-icons/ai";
import { Modal, Skeleton, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import ChangePassword from "./ChangePassword";
import { dispatchtoast } from "../../../Utils";
import { BiSolidPencil } from "react-icons/bi";
import { setSelectedTab, setProfilePic } from "../../../Store/jetbetslice";
import EditProfile from "./EditProfile";
import { ProfileDetail, UpdateProfileImage } from "../../../ApiServices/Apifun";

const Settings = () => {
  const [activeTab, setActiveTab] = useState(1);
  const [selectedImage, setSelectedImage] = useState(null);
  const [name, setName] = useState("");
  const [spinner, setSpinner] = useState(false);
  const fileInputRef = useRef(null);
  const [openImageModal, setOpenImageModal] = useState(null);
  const userDetail = useSelector((state) => state.jatbet.userDetails);
  const profilePic = useSelector((state) => state.jatbet.profilePicture);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSelectedTab(0));
    getProfileDetail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = () => {
    fileInputRef.current.click();
  };

  const handleImageChange = () => {
    setSpinner(true);
    const formdata = new FormData();
    const file = fileInputRef.current.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
      formdata.append("image", file);
      UpdateProfileImage(formdata)
        .then((response) => {
          if (response.status) {
            getProfileDetail();
            dispatchtoast("Image Updated Successfully");
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => setSpinner(false));
    }
  };

  const getProfileDetail = () => {
    ProfileDetail()
      .then((res) => {
        setName(res.data.name);
        if (res.data.profile_image!==null) {
          dispatch(
            setProfilePic(
              "https://api.joc.today" +
                "/uploads/userProfile/" +
                res.data.profile_image
            )
          );
        }
      })
      .catch((err) => console.log(err));
  };
  return (
    <>
      <Spin spinning={spinner}>
        <div className="bg-white rounded-xl">
          <div className="flex flex-wrap justify-start gap-x-8 items-start p-4">
            <div className="user_image relative">
              {profilePic ? (
                <img
                  crossOrigin="anonymous"
                  src={profilePic}
                   onClick={() => setOpenImageModal(true)}
                  alt="userImg"
                  className="rounded-full border-4 border-primary w-24 h-24"
                />
              ) : (
                <Skeleton.Avatar active={true} size={100} shape={"circle"} />
              )}
              <div className="absolute bottom-0 right-1">
                <input
                  type="file"
                  accept="image/*"
                  onChange={() => {
                    handleImageChange();
                    fileInputRef.current.value = "";
                  }}
                  style={{ display: "none" }}
                  ref={fileInputRef}
                />
                <BiSolidPencil
                  size={20}
                  onClick={handleClick}
                  className="text-white bg-primary rounded-full w-5 h-5 p-1 cursor-pointer"
                />
              </div>
            </div>
            <div className="details mt-4">
              <p className="font-bold text-xl">{name}</p>
              <p className="font-semibold text-xs md:text-sm">
                {userDetail.userProfile?.mobile ?? "-"}
              </p>
              <p className="font-medium text-xs">
                {userDetail.userProfile?.email ?? "-"}
              </p>
            </div>
          </div>
        </div>
      </Spin>
      <div className="bg-white rounded-md p-4 mt-4 mb-3">
        <div className="flex flex-wrap justify-start items-center text-[10px] xs:text-[13px] md:text-[14px] gap-0 md:gap-4 ">
          <div>
            <div
              onClick={() => setActiveTab(1)}
              className="flex justify-center gap-2 items-center p-2"
            >
              <div>
                <AiOutlineUser className="text-primary" size={20} />
              </div>
              <p className="cursor-pointer">Profile</p>
            </div>
            {activeTab === 1 ? (
              <div className="border-t-2 p-2 animate-slide-in border-primary"></div>
            ) : (
              <div className="p-2"></div>
            )}
          </div>
          <div>
            <div
              onClick={() => setActiveTab(2)}
              className="flex justify-center gap-2 items-center p-2"
            >
              <div>
                <MdOutlinePassword className="text-primary" size={20} />
              </div>
              <p className="cursor-pointer">Change Password</p>
            </div>
            {activeTab === 2 ? (
              <div className="border-t-2 p-2 animate-slide-in border-primary"></div>
            ) : (
              <div className="p-2"></div>
            )}
          </div>
        </div>
      </div>
      {activeTab === 2 ? (
        <ChangePassword />
      ) : (
        <EditProfile getProfileDetail={getProfileDetail} />
      )}
      <Modal
          className="image-Modal"
          footer={null}
          width={256}
          onCancel={()=>setOpenImageModal(false)}
          open={openImageModal}
        >
          <img crossOrigin="anonymous" src={profilePic} className="w-64 h-64 rounded-full" />
        </Modal>
    </>
  );
};

export default Settings;
