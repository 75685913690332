import {  FormDataPost, Get, Post, getAPIUrl } from "./apiMethod";
import { Authendpoint, Globalendpoints } from "./global";

export const userLogin = (data, param = "") => {
  const url = getAPIUrl(Authendpoint.Login, param);
  return Post(url, data, false);
};
export const forgotPassword = (data, param = "") => {
  const url = getAPIUrl(Authendpoint.forgotPassword, param);
  return Post(url, data, false);
};
export const verifyOtpafterForgot = (data, param = "") => {
  const url = getAPIUrl(Authendpoint.verifyOtpafterForgot, param);
  return Post(url, data, false);
};
export const ResnedOTP = (data, param = "") => {
  const url = getAPIUrl(Authendpoint.resendOTP, param);
  return Post(url, data, false);
};
export const verifyOtpafterLogin = (data, param = "") => {
  const url = getAPIUrl(Authendpoint.VerifyOtpafterLogin, param);
  return Post(url, data, false);
};
export const resetPassword = (data, param = "") => {
  const url = getAPIUrl(Authendpoint.resetPassword, param);
  return Post(url, data, false);
};
export const userRegister = (data, param = "") => {
  const url = getAPIUrl(Authendpoint.register, param);
  return Post(url, data, false);
};
export const getPoolList = (param = "") => {
  const url = getAPIUrl(Globalendpoints.pools, param);
  return Get(url);
};
export const getRank = (param = "") => {
  const url = getAPIUrl(Globalendpoints.quizRank, param);
  return Get(url);
};
export const getQuestions = (param = "") => {
  const url = getAPIUrl(Globalendpoints.GetQuizQuestion, param);
  return Get(url);
};
export const saveQuizDetail = (data,param = "") => {
  const url = getAPIUrl(Globalendpoints.saveQuizDetail, param);
  return Post(url,data);
};
export const changePassword = (data,param = "") => {
  const url = getAPIUrl(Globalendpoints.changePassword, param);
  return Post(url,data);
};
export const UpdateProfile = (data,param = "") => {
  const url = getAPIUrl(Globalendpoints.updateProfile, param);
  return Post(url,data);
};
export const UpdateProfileImage = (data,param = "") => {
  const url = getAPIUrl(Globalendpoints.updateProfile, param);
  return FormDataPost(url,data);
};
export const ProfileDetail = (param = "") => {
  const url = getAPIUrl(Globalendpoints.ProfileDetail, param);
  return Get(url);
};
export const getBannerImage = (param = "") => {
  const url = getAPIUrl(Globalendpoints.getImages, param);
  return Get(url);
};