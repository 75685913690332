import { Modal } from "antd";
import React, { useEffect, useState } from "react";
import Lottie from "react-lottie";
import * as troffe from "../../../Assets/troffe.json";
import { getRank } from "../../../ApiServices/Apifun";
import { dispatchtoast } from "../../../Utils";
const ResultModal = ({ isOpen, setIsOpen, id }) => {
  const [activeTab, setactiveTab] = useState(1);
  const [result, setResult] = useState({
    first: null,
    second: null,
    third: null,
  });
  useEffect(() => {
    getRank(`?role=user&pool_id=${id}`)
      .then((res) => {
        if (res.status) {
          res.data
            ? dispatchtoast(res.message)
            : dispatchtoast(res.message, true);
          const first =
            res.data.length !== 0 ? res.data.filter((i) => i.rank === "1") : null;
          const second =
            res.data.length !== 0 ? res.data.filter((i) => i.rank === "2") : null;
          const third =
            res.data.length !== 0 ? res.data.filter((i) => i.rank === "3") : null;
          setResult({ first, second, third });
        }
      })  
      .catch((err) => console.log(err));
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Modal
        footer={null}
        title="Result"
        open={isOpen}
        onCancel={() => setIsOpen(false)}
      >
        <div className="bg-primary rounded-md">
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: troffe,
            }}
            height={200}
            width={200}
          />
          {Object.values(result).every((i) => i !== null) ? (
            <div className="bg-primary rounded-md p-4 mb-3">
              <div className="flex flex-wrap justify-around font-semibold items-center text-[10px] xs:text-[13px] md:text-[14px] gap-0 md:gap-4 ">
                <div>
                  <div
                    onClick={() => setactiveTab(1)}
                    className="flex justify-center gap-2 items-center p-2"
                  >
                    <div></div>
                    <p
                      className={`cursor-pointer text-white  ${
                        activeTab === 1 &&
                        "sm:bg-white animate-fade-in sm:text-black"
                      } p-4 rounded-full xs:px-0 sm:px-4 md:px-5 py-2`}
                    >
                      First
                    </p>
                  </div>
                </div>
                <div>
                  <div
                    onClick={() => setactiveTab(2)}
                    className="flex justify-center gap-2 items-center p-2"
                  >
                    <div></div>
                    <p
                      className={`cursor-pointer text-white  ${
                        activeTab === 2 &&
                        "sm:bg-white animate-fade-in sm:text-black"
                      } p-4 rounded-full xs:px-0  sm:px-4 md:px-5 py-2`}
                    >
                      Second
                    </p>
                  </div>
                </div>
                <div>
                  <div
                    onClick={() => setactiveTab(3)}
                    className="flex justify-center gap-2 items-center p-2"
                  >
                    <div></div>
                    <p
                      className={`cursor-pointer text-white  ${
                        activeTab === 3 &&
                        "sm:bg-white animate-fade-in sm:text-black"
                      } p-4 rounded-full xs:px-0 sm:px-4 md:px-5 py-2`}
                    >
                      Third{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <p className="text-center font-semibold text-white">
              Winner Not set yet!
            </p>
          )}
        </div>
        <div className="max-h-[200px] overflow-y-scroll">
          {activeTab === 1 ? (
            result.first?.length !== 0 ? (
              result.first?.map((i) => {
                return (
                  <div className="grid grid-cols-3 place-items-center bg-blue-300 my-3 rounded-md py-2 mx-2 text-white font-semibold">
                    <p className="bg-blue-600 rounded-full  flex justify-center items-center w-8 h-8">
                      {i.rank}
                    </p>
                    <p>{i.name??"-"}</p>
                  <p>{i.hasOwnProperty("point")?`${i.point} P`:"0 P"}</p>
                  </div>
                );
              })
            ) : (
              <p className="text-center font-semibold">No one</p>
            )
          ) : activeTab === 2 ? (
            result.second?.length !== 0 ? (
              result.second?.map((i) => {
                return (
                  <div className="grid grid-cols-3 place-items-center bg-blue-300 my-3 rounded-md py-2 mx-2 text-white font-semibold">
                    <p className="bg-blue-600 rounded-full  flex justify-center items-center w-8 h-8">
                      {i.rank}
                    </p>
                    <p>{i.name??"-"}</p>
                  <p>{i.hasOwnProperty("point")?`${i.point} P`:"0 P"}</p>
                  </div>
                );
              })
            ) : (
              <p className="text-center font-semibold">No one</p>
            )
          ) : result.third?.length !== 0 ? (
            result.third?.map((i) => {
              return (
                <div className="grid grid-cols-3 place-items-center bg-blue-300 my-3 rounded-md py-2 mx-2 text-white font-semibold">
                  <p className="bg-blue-600 rounded-full  flex justify-center items-center w-8 h-8">
                    {i.rank}
                  </p>
                  <p>{i.name??"-"}</p>
                  <p>{i.hasOwnProperty("point")?`${i.point} P`:"0 P"}</p>
                </div>
              );
            })
          ) : (
            <p className="text-center font-semibold">No one</p>
          )}
        </div>
      </Modal>
    </>
  );
};

export default ResultModal;
